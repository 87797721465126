import { render, staticRenderFns } from "./Main.vue?vue&type=template&id=5f38f832&scoped=true&"
import script from "./Main.vue?vue&type=script&lang=js&"
export * from "./Main.vue?vue&type=script&lang=js&"
import style0 from "./Main.vue?vue&type=style&index=0&id=5f38f832&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f38f832",
  null
  
)

export default component.exports