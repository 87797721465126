import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts'
// 自定义指令
import '@/utils/preventReClick.js'

Vue.prototype.$echarts = echarts;
Vue.use(ElementUI);
Vue.config.productionTip = false


// 免登录 路由守卫
router.beforeEach((to,form,next)=>{
  let token;
  if(localStorage.getItem('token')){
    token = localStorage.getItem('token') 
  }else{
    token = sessionStorage.getItem('token')
  }
  // 更换title
  window.document.title = to.meta.title 
  //未登录 不在登录页面
  if(!token && to.name !== 'login'){
    next({ name:'login' })
  }else if(token && to.name === 'login'){ //在登录页 有token
    next({ name:'home' })
  }else{
    next()
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
