<template>
    <div class="main">
        <el-container>
            <el-header>
                <navMenu />
            </el-header>
           
            <el-main>
                <router-view></router-view>
            </el-main>
          
         </el-container>
    </div>
</template>

<script>
import navMenu from '@/components/NavMenu.vue'

    export default {
       components:{
        navMenu,
       }
    }
</script>

<style lang="scss" scoped>
    .main{
        background-color: rgba(235, 236, 237, 1);
        .el-header{
            padding:0;
        }
        .el-main{
            margin-top:24px;
            padding:0;
            height:calc(100vh - 88px);
        }
    }
</style>


       
     
