<template>
  <div class="HomeView">
    <el-row :gutter="12">
      <el-col :span="4">
          <el-card>
              <div class="cardBox">
                <img class="card-icon" src="@/assets/images/orderSum.png" alt="">
                <div>
                    <p class="tit">订单总金额</p>
                    <p class="num" style="">￥{{ statistics.sumOrderAmount }}</p>
                </div>
              </div>
          </el-card>
      </el-col>
      <el-col :span="4">
        <el-card>
              <div class="cardBox">
                <img class="card-icon" src="@/assets/images/electricitySum.png" alt="">
                <div>
                    <p class="tit">已充总电量</p>
                    <p class="num" >￥{{ statistics.sumChargeCapacity }}</p>
                </div>
              </div>
          </el-card>
      </el-col>
      <el-col :span="4">
        <el-card>
              <div class="cardBox">
                <img class="card-icon" src="@/assets/images/dianSum.png" alt="">
                <div>
                    <p class="tit">总电费</p>
                    <p class="num">￥{{ statistics.sumElectricityFees }}</p>
                </div>
              </div>
          </el-card>
      </el-col>
      <el-col :span="4">
        <el-card>
              <div class="cardBox">
                <img class="card-icon" src="@/assets/images/serviceSum.png" alt="">
                <div>
                    <p class="tit">总服务费</p>
                    <p class="num">￥{{ statistics.sumServiceFees }}</p>
                </div>
              </div>
          </el-card>
      </el-col>
      <el-col :span="4">
        <el-card>
              <div class="cardBox">
                <img class="card-icon" src="@/assets/images/youhuiSum.png" alt="">
                <div>
                    <p class="tit">总优惠金额</p>
                    <p class="num">￥{{ statistics.sumGiveBalance }}</p>
                    
                </div>
              </div>
          </el-card>
      </el-col>
      <el-col :span="4">
        <el-card>
              <div class="cardBox">
                <img class="card-icon" src="@/assets/images/sumIncome.png" alt="">
                <div>
                    <p class="tit">净收入</p>
                    <p class="num">￥{{ statistics.sumIncome }}</p>      
                </div>
              </div>
          </el-card>
      </el-col>
    </el-row>

    <div class="main-area" style="padding:16px;">
      <el-row :gutter="24">
         <el-card>
            <div ref="main" style="width: auto; height: 400px" id="main"></div>
         </el-card>
      </el-row>

      <el-row :gutter="24" style="margin-top:16px;">
         <el-card>
            <h4 style="padding-left:16px;">电站排行</h4>
            
            <Table :Data="tableData"></Table>
            
         </el-card>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getHome } from '@/api/admin';
import Table from '@/components/Table.vue'
  export default {
    components:{ Table },
    data() {
      return {
            myChart:null,
            option: {
                tooltip:{
                  trigger: 'axis',
                  textStyle: { // 悬浮框文字样式
                      color: 'rgba(0, 194, 80, 1)',
                      fontSize: 14, 
                  }
                },
                title: {
                    left:'10px',
                    text: '订单金额'
                },
                // 距离容器的距离
                grid:[
                    {
                        // grid 组件离容器下侧的距离
                        bottom:'20px',
                        left:'60px'
                    }
                ],
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: []
                },
                yAxis: {
                    type: 'value',
                    axisLabel: {formatter: '{value} 元'},
                    // name:'订单金额'
                },
                series: [
                    {
                        data: [],
                        name: '订单金额',
                        type: 'line',
                        areaStyle: {},
                        itemStyle: {
                            normal: {
                              // 修改圆点距离
                            color:'#00c250',
                            areaStyle: {
                                type: 'default',
                                //渐变色实现===
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1,
                                //三种由深及浅的颜色
                                [{
                                    offset: 0,
                                    color:'rgba(167, 255, 153, 1)',
                                    
                                }, {
                                    offset: 1,
                                    color: 'rgba(255, 255, 255, 1)'
                                }]),
                            },
                            lineStyle: {  //线的颜色
                                color: '#00c250'
                            },
                            //以及在折线图每个日期点顶端显示数字
                            label: {
                                // show: true,
                                position: 'top',
                                textStyle: {
                                color: 'white'
                                }
                            }
                            },
                        },
                        // // 圆点颜色
                        // itemStyle:{
                        //   color:'rgba(167, 255, 153, 1)'
                        // },
                        // // 图层区域颜色
                        // areaStyle: {
                        //     color: 'rgba(167, 255, 153, 1)',
                        //     opacity: 0.4
                        // },
                        smooth: true
                    }
                ]
            },
            tableData: {
                    data:[],//子组件显示数据
                    slot:false,//是否有操作列
                    order:'电站排名',//有排名
                    thead:[{
                        label:"电站名称",
                        prop:'siteName'
                    },{
                        label:'总金额',
                        prop:'orderAmount',
              
                    },{
                        label:'电量',
                        prop:'chargeCapacity',

                    },{
                        label:'电费',
                        prop:'electricityFees',
 
                    },{
                        label:'服务费 ',
                        prop:'serviceFees',
       
                    },{
                        label:'礼券',
                        prop:'giveBalance',
      
                    },{
                        label:'净收入',
                        prop:'income',
                        type:'function',
                        class:'content-color-act',
                        callback:(row)=>{
                           return `￥${row.income}`
                        }
                    }],
            },
            statistics:{},//card 数据
      }
    },
    methods:{
      getData(){
        getHome().then(res=>{
          this.statistics = res.data.statistics;
          // 电站排名表格数据
          this.tableData.data = res.data.statisticsSiteData;
          
          // echarts 图表数据
          res.data.statisticsOrderData.forEach(item=>{
            this.option.xAxis.data.push(item.dateTime);
            this.option.series[0].data.push(item.orderAmount);
          })
          // 有数据后在渲染 避免图表空白
          this.echartsInit();
        })
      },
       //初始化echarts
      echartsInit() {
            //初始化echarts 时，指定的容器 
            this.myChart = this.$echarts.init(this.$refs.main)
            this.myChart.setOption(this.option)
            // 监听屏幕尺寸变化 动态更改echarts图标 自适应
            window.addEventListener('resize',()=>{
                this.myChart.resize()
            })
      }
    },
    mounted(){
      this.getData();
    },
    // 页面注销时 取消  避免内存泄漏
    beforeDestroy(){
        window.removeEventListener('resize',()=>{
            this.myChart.resize();
        })
    }
  }
</script>

<style lang="scss" scoped>
  .HomeView{
    padding:16px;
    box-sizing: border-box;
    .cardBox{
      display:flex;
      align-items:center;
      .el-card{
        border-radius:4px;
      }
      .card-icon{
        width:60px;
        height:60px;
        display:block;
        margin-right:10px; 
      }
      .tit{
        font-weight:bold;
        color:#333;
      }
      .num{
        margin-top:8px;
        font-size:20px;
        font-weight: 700;
        color:rgba(51, 51, 51, 1);
      }
    }
    .main-area{
      .el-card{
        border-radius:8px;
      }
    }
  }
 
</style>

