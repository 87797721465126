import axios from 'axios'
import options from '@/utils/globalConfig.js'
import { Loading,Message } from 'element-ui';
import router from '@/router'

// 测试地址
// let url = 'http://192.168.0.93:8888/api';
// let url = 'https://5501260u9k.oicp.vip/api';

let url = 'https://fl.daodingtech.com/api';
let loading;
let loadingNum = 0;
let state = 0;
// 加载提示
function startLoading() {
    if(loadingNum == 0) {
        loading = Loading.service({
            lock: true,
            text: '拼命加载中...',
            background:'rgba(255,255,255,0.5)', 
        })
    }
    loadingNum++;
}
// 取消加载
function closeLoading() {  //  关闭加载动画
    loadingNum--;
    if(loadingNum <= 0) {
        loading.close();
    }
}
// 消息提示框
function MessageBox(msg = '',type = 'success',duration = 3000){
    Message({
        message:msg,
        type,
        duration
    })
}

const http = axios.create({     
    baseURL:url,
    headers:{
        'Content-Type':'application/json',
    },
    timeout:30000,  
})

// 请求拦截器
http.interceptors.request.use((req)=>{
    let token = sessionStorage.getItem('token');
    // console.log(token) 
    let whiteListApi = options.whiteListApi
    let url = req.url
    if(url != '/captchaImage'){
        startLoading();
        state = 1;
    }else{
        state = 0;
    }


    if(whiteListApi.indexOf(url) == -1 && token && url != '/store/upload/activity'){
        req.headers['Authorization'] = token;
    }else if(url == '/store/upload/activity'){
        // 上传图片 更换请求头类型
        req.headers['Content-Type'] = 'multipart/form-data'
        req.headers['Authorization'] = token;
    }
    // 在发送请求前
    return req;
},(error)=>{
    // 对请求错误
    return Promise.reject(error);
})

// 响应拦截器
http.interceptors.response.use((res)=>{ 
    // console.log(res)
    if(state == 1){
        closeLoading();
    }
    if(res.data.code == 401){
        // Message({
        //     type:'warning',
        //     tyepe:'登录超时，请重新登录'
        // });
        sessionStorage.clear();
        router.push('/login')
        // window.location.href = "/login";
    }else if(res.data.code == 500){
        MessageBox(res.data.msg,'warning')
    }
    return res.data
},(error)=>{
    closeLoading();
    MessageBox('网络走丢了，请检查网络','warning',5000)
    // 对响应错误操作 $message 失败消息提示
    return Promise.reject(error)
})

export default http