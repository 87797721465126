<template>
  
      <el-col class="NavMenu" :span="24">
        <div class="nav-left">
          <div class="logo">       
            <img class="imgLogo" src="@/assets/images/company-log.png" alt="">
          </div>
                    
            <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal">
                <el-menu-item @click="selectMenu(item)" :index="item.name" v-for="item in navMenuList" :key="item.id">
                    {{ item.label }}
                </el-menu-item>
                <el-menu-item @click="goFran" v-if="isXT" >
                  加盟商
                </el-menu-item>
            </el-menu>
          
        </div>
           
        <div class="nav-right">       
              <el-dropdown trigger="click" @command="handleCommand">
                <div class="el-dropdown-link">
                  <img :src='avatar' class="avatar" alt="">
                  <div class="userName">
                      <p>{{ nickName }}</p>
                      <p>{{ isAdmin==0?'管理员':'用户' }}</p>
                   </div>
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="personal">个人中心</el-dropdown-item>
                  <el-dropdown-item command="exit">退出</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
        </div>
      </el-col>
  
  </template>
  
  <script>
  import { getInfo } from '@/api/admin.js'
    export default {
      data() {
        return {
          isXT:'',
          franState:null,//加盟商项是否可见
          navMenuList:[{
              id:1,
              path:'/',
              name:'home',
              label:'首页',
          },{
              id:2,
              path:'/orderList',
              name:'order',
              label:'订单管理',  
          },{
              id:3,
              path:'/powerstation',
              name:'powerstation',
              label:'电站管理',
          },{
              id:4,
              path:'/chargeUser',
              name:'user',
              label:'用户管理',
          },{
              id:5,
              path:'/rechargeAct',
              name:'act',
              label:'营销活动',
          },{
              id:6,
              path:'/rechargeOrderSta',
              name:'statistics',
              label:'数据统计',
          },{
              id:7,
              path:'/enterPriseInfo',
              name:'enterprise',
              label:'企业管理',
          },
          // {
          //     id:8,
          //     path:'/franchisee',
          //     name:'franchisee',
          //     label:'加盟商',
          //     isSee:false,
          // },
          ],
          search:'',
          avatar:null,
          nickName: null,
          isAdmin:null,
        };
      },
      methods: {
        goFran(){
          if(this.$route.name != 'franchiseeList'){
            this.$router.push({
              name:'franchisee'
            })
          }
        },
        // 退出
        handleCommand(command) {
          switch(command){
            case 'exit':
                //  清空sessionStorage 数据
                sessionStorage.clear()
                this.$store.commit('updateToken','')
                this.$router.replace('/login')  
            break;
            case 'personal':
                this.$router.push('/personal')
            break;
          }
        },
        // 路由跳转
        selectMenu(item) {
          if(this.$route.path != item.path && !(this.$route.path === '/home' && item.path==='/')){
            this.$router.push(item.path)
          }
        },
        getUserInfo(){
          getInfo().then(res => {
            if(res.code == 200){
              this.avatar = res.user.avatar;
              this.nickName = res.user.nickName;
              this.isAdmin = res.user.isAdmin;
              // 权限判断是否未小碳
              let entId = res.user.entId;
              this.isXT = entId == '1075692846516146176'
              sessionStorage.setItem('userInfo',JSON.stringify(res.user))
              let userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
              this.$store.commit('updateUserInfo',userInfo)
            }
          })
        }
      },
      computed:{
        activeIndex(){
          let name = this.$route.name;   
          switch(name){
            case 'chargeUser' : 
              return 'user';
            case 'rechargeRecord' : 
              return 'user';
            case 'giftRecord' : 
              return 'user';
            case 'userInfo' : 
              return 'user';
            case 'home' : 
              return 'home';
            case 'powerstation' : 
              return 'powerstation';
            case 'powerStationDetiles' : 
              return 'powerstation';
            case 'rechargeAct':
              return 'act';
            case 'otherAct':
              return 'act';
            case 'orderList':
              return 'order';
            case 'afterSales':
              return 'order';
            case 'orderDetiles':
              return 'order';
            case 'enterPriseInfo':
              return 'enterprise';
            case 'message':
              return 'enterprise';
            case 'wxSet':
              return 'enterprise';
            case 'adminList':
              return 'enterprise';
            case 'rechargeOrderSta':
              return 'statistics';
            case 'franchiseeList':
              return 'franchisee';
            case 'franchiseeDetiles':
              return 'franchisee';
            case 'WithdrawalList':
              return 'franchisee';
            default:
              return 'home'
          }
        }
      },
      mounted(){
        // this.line();   
        this.getUserInfo();
      },
      
    }
  </script>
  
  <style lang="scss" scoped>
    .NavMenu{
      background: rgba(255, 255, 255, 1);
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      padding:16px;
      display:flex;
      height:88px;
      justify-content: space-between;
      align-items: center;
      background:#FFF;
      .nav-left{
        display:flex;
        align-items: center;
        padding-left:16px;
        .logo{
          margin-right:82px;
          .imgLogo{
            width:154px;
            height:44px;
            display:block;
            -webkit-user-drag: none;//禁止用户拖动
          }
        }
        .el-menu-demo{
          border-bottom:none;
        }
        .el-menu--horizontal{
          height:80px;
          font-size:18px !important;
        }
        .el-menu--horizontal>.el-menu-item{
          font-size:18px;
          padding:10px 15px;
          margin-right:10px;
        }
        .el-menu--horizontal>.el-menu-item.is-active{
          color:rgba(0,194,80,1);
          border-bottom-color:rgba(0,194,80,1);
        }
      }
      .nav-right{
        display:flex;
        align-items: center;
        padding-right:20px;
        .el-dropdown-link{
          display:flex;
          justify-content: center;
          align-items:center;
          margin-left:16px;
          .avatar{
            display: block;
            width:32px;
            height:32px;
            border-radius:50%;
            -webkit-user-drag: none;//禁止用户拖动
          }
          .userName{
            margin-left:8px;
            margin-right:12px;
            display: flex;
            flex-direction: column;
            align-items: center;
            :first-child{
              font-size:14px;
            }
            :last-child{
              font-size:12px;
              color: rgba(0, 194, 80, 1);
              background-color: #E0FFED;
            }
          }
        }
      }
    }
  </style>