import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '@/views/Main.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'main',
    component: Main,
    redirect:'/home',
    children:[
      {
        path:'home',
        name:'home',
        component:Home,
        meta:{
          title:'小碳充电-首页'
        }
      },
      {
        path:'deviceLog',
        name:'deviceLog',
        component:() => import ('@/views/DeviceLog.vue'),
        meta:{
          title:'小碳充电-设备详情'
        }
      },
      {
        path: 'enterprise',
        name: 'enterprise',
        component: () => import('@/views/EnterPrise.vue'),
        meta:{
          title:'小碳充电-企业管理'
        },
        redirect:'/enterPriseInfo',
        children:[
          {
            path:'/message',
            name:'message',
            component: () => import('@/views/enterPriseChildren/Message.vue'),
            meta:{
              title:'小碳充电-企业管理'
            }
          },
          {
            path:'/enterPriseInfo',
            name:'enterPriseInfo',
            component: () => import('@/views/enterPriseChildren/EnterPriseInfo.vue'),
            meta:{
              title:'小碳充电-企业管理'
            }
          },
          {
            path:'/wxSet',
            name:'wxSet',
            component: () => import('@/views/enterPriseChildren/WxSet.vue'),
            meta:{
              title:'小碳充电-企业管理'
            }
          },
          {
            path:'/adminList',
            name:'adminList',
            component:()=> import('@/views/enterPriseChildren/AdminList.vue'),
            meta:{
              title:'小碳充电-企业管理'
            }
          },
        ]
      },
      {
        path: 'powerstation',
        name: 'powerstation',
        component: () => import('@/views/PowerStation.vue'),
        meta:{
          title:'小碳充电-电站管理'
        }
      },
      {
        path: 'powerStationDetiles',
        name: 'powerStationDetiles',
        component: () => import('@/views/powerStationChildren/PowerStationDetiles.vue'),
        meta:{
          title:'小碳充电-电站管理'
        }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/User.vue'),
        redirect:'/chargeUser',
        children:[{
          path:'/chargeUser',
          name:'chargeUser',
          component: () => import('@/views/userChildren/ChargeUser.vue'),
          meta:{
            title:'小碳充电-用户管理'
          }
        },{
          path:'/rechargeRecord',
          name:'rechargeRecord',
          component: () => import('@/views/userChildren/RechargeRecord.vue'),
          meta:{
            title:'小碳充电-用户管理'
          }
        },{
          path:'/giftRecord',
          name:'giftRecord',
          component: () => import('@/views/userChildren/GiftRecord.vue'),
          meta:{
            title:'小碳充电-用户管理'
          }
        },{
          path:'/giveRecord',
          name:'giveRecord',
          component: () => import('@/views/userChildren/GiveRecord.vue'),
          meta:{
            title:'小碳充电-用户管理'
          }
        },{
          path: '/userInfo',
          name: 'userInfo',
          component: () => import('@/views/userChildren/UserInfo'),
          meta:{
            title:'小碳充电-用户管理'
          }
        },]
      },
      {
        path: 'act',
        name: 'act',
        component: () => import('@/views/Activity.vue'),
        redirect:'/rechargeAct',
        children:[
          {
            path:'/rechargeAct',
            name:'rechargeAct',
            component: () => import('@/views/activityChildren/RechargeActivity.vue'),
            meta:{
              title:'小碳充电-活动管理'
            }
          },
          {
            path:'/userVip',
            name:'userVip',
            component: () => import('@/views/activityChildren/UserVip.vue'),
            meta:{
              title:'小碳充电-活动管理'
            }
          },
          {
            path:'/otherAct',
            name:'otherAct',
            component: () => import('@/views/activityChildren/OtherActivity.vue'),
            meta:{
              title:'小碳充电-活动管理'
            }
        }]
      },
      {
        path:'order',
        name:'order',
        component: () => import('@/views/Order.vue'),
        redirect:'/orderList',
        children:[
          {
            path:'/orderList',
            name:'orderList',
            component: () => import('@/views/orderChildren/OrderList.vue'),
            meta:{
              title:'小碳充电-订单列表',
              keepAlive:true,
            }
          },
          {
            path:'/orderDetiles',
            name:'orderDetiles',
            component: () => import('@/views/orderChildren/OrderDetiles.vue'),
            meta:{
              title:'小碳充电-订单列表'
            }
          },
          {
            path:'/afterSales',
            name:'afterSales',
            component: () => import('@/views/orderChildren/AfterSales.vue'),
            meta:{
              title:'小碳充电-售后管理'
            }
        }]
      },
      {
        path:'statistics',
        name:'statistics',
        component:()=> import('@/views/Statistics.vue'),
        redirect:'/rechargeOrderSta',
        children:[
          {
            path:'/rechargeOrderSta',
            name:'rechargeOrderSta',
            component: () => import('@/views/statisticsChildren/rechargeOrderSta.vue'),
            meta:{
              title:'小碳充电-数据统计'
            }
        },]
      },
      {
        path:'personal',
        name:'personal',
        component:()=> import('@/views/Personal.vue'),
        meta:{
          title:'小碳充电-个人中心'
        }
      },
      {
        path: 'franchisee',
        name: 'franchisee',
        redirect:'/franchiseeList',
        component: () => import('@/views/Franchisee.vue'), 
        children:[
          {
            path:'/franchiseeList',
            name:'franchiseeList',
            component: () => import('@/views/franchiseeChidren/FranchiseeList.vue'),
            meta:{
              title:'小碳充电-加盟商'
            },
          },
          {
            path:'/franchiseeDetiles',
            name:'franchiseeDetiles',
            component: () => import('@/views/franchiseeChidren/FranchiseeDetiles.vue'),
            meta:{
              title:'小碳充电-加盟商'
            },
          },
          {
            path:'/WithdrawalList',
            name:'WithdrawalList',
            component: () => import('@/views/franchiseeChidren/WithdrawalList.vue'),
            meta:{
              title:'小碳充电-加盟商'
            },
          },
        ]
      },
    ]
  }, 
  {
    path:'/login',
    name:'login',
    component: () => import('@/views/Login.vue'),
    meta:{
      title:'小碳充电-登录'
    }
  },
  {
    path:'*',
    redirect:'/'
  },


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
