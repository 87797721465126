import http from '../utils/request';

// 商户注册
export const register = (params) => {
    return http.post('/store/member/register',params)
}

// 登录
export const login = ( params = {}) => {
    return http.post('/store/member/login',params)
}

// 登录验证码
export const captchaImage = () => {
    return http.get('/captchaImage')
}

// 账号信息
export const getInfo = () => {
    return http.get('/store/member/getInfo')
}

// 首页
export const getHome = () => {
    return http.get(`/store/index/`)
}
// 通用上传接口
export const uploadFile = (params) =>{
    return http.post(`/store/upload/activity`,params)
}

// 商户管理员列表
export const getAdminList = (pageNum,pageSize) =>{
    return http.post(`/store/member/list?pageNum=${pageNum}&pageSize=${pageSize}`)
}
// 新增管理员
export const addAdmin = (params) => {
    return http.post(`/store/member`,params)
}
// 修改管理员信息
export const updateAdmin = (params) => {
    return http.put(`/store/member`,params)
}
// 查询管理员详细信息
export const getAdminDetiles = (oprId) =>{
    return http.get(`/store/member/${oprId}`,)
}
// 删除商户管理员
export const delAdmin = (oprIds) =>{
    return http.delete(`/store/member/${oprIds}`,)
}
// 状态修改
export const updateAdminStatus = (params) => {
    return http.put(`/store/member/changeStatus`,params)
}