<template>
    <div style="padding:16px;background:#fff;">
        <el-table
            :data="table"
            :row-key="Data.rowKey"
            :default-expand-all="Data.expand"
            :tree-props="{children: Data.children, hasChildren: 'hasChildren'}"
            style="width: 100%">
            <!-- 是否显示序号 -->
            <el-table-column
                    v-if="Data.order"
                    :label="Data.order"
                    type="index"
                    width="80">
            </el-table-column>
            <template  v-for="item of Data.thead">       
                <!-- 有回调 -->
                <el-table-column
                    v-if="item.type == 'function'"
                    :key="item.label"
                    :label="item.label"
                    :prop="item.prop"
                    :width="item.width">
                    <template slot-scope="scope"> 
                        <span v-if="item.classType == 'specialClass'" :class="item.class(scope.row)">{{ item.callback && item.callback(scope.row) }}</span>
                        <span v-else :class="item.class">{{ item.callback && item.callback(scope.row) }}</span>
                    </template>
                </el-table-column>
                <!-- 表格内显示图片 -->
                <el-table-column
                    v-else-if="item.img == 'img' "
                    :key="item.label"
                    :label="item.label"
                    width="80"
                    :prop="item.prop">
                    <template slot-scope="scope">   
                        <img :class="item.class" :src="item.callback(scope.row)" alt="">
                    </template>
                </el-table-column>
                <!-- 表格内显示开关 -->
                <el-table-column
                    v-else-if="item.switch == 'switch' "
                    :key="item.label"
                    :label="item.label"
                    :prop="item.prop">
                    <template slot-scope="scope">   
                        <el-switch @change="stateChange(scope.row)" v-model="scope.row[item.prop]"></el-switch>
                    </template>
                </el-table-column>
                <!-- 无回调正常 显示 -->
                <el-table-column
                    v-else
                    :key="item.label"
                    :label="item.label"
                    :prop="item.prop"
                    :width="item.width">
                    <template slot-scope="scope">   
                        <!-- <span :class="item.class">{{ scope.row[item.prop] ? scope.row[item.prop]:'暂无数据' }}</span> -->
                        <span :class="item.class">{{ scope.row[item.prop]  }}</span>
                    </template>
                </el-table-column>
            </template>

            <!-- slot 判断是否有操作列 -->
            <template v-if="Data.slot">
                <el-table-column label="操作" :width="Data.slotWidth">
                    <template slot-scope="scope">
                        <slot :row="scope.row" :index="scope.$index"></slot>
                    </template>
                </el-table-column>
            </template>
        </el-table>

    </div>
</template>

<script>

    export default {
        props:{ Data:Object,default:{} },
        data() {
            return {
                table: [],//页面数据  动态获取
            }
        },
        methods:{
            // 开关改变
            stateChange(row){
                this.$emit('stateChange',row)
            }
        },
        watch:{
            Data:{
                deep:true,
                handler(value){
                    if(value.data){
                        this.table = value.data
                    }
                }
            }
        },
       
    }
</script>

<style lang="scss" scoped>
    .content-border{
        border:2px solid #00c250;
        width:60px;
        text-align:center;
        color:rgba(0, 194, 80, 1);
        padding:2px 4px;
        display:block;
        border-radius:4px;
        box-sizing:border-box;
    }
    .content-border-plus{
        width:80px;
        border:2px solid #00c250;
        text-align:center;
        color:rgba(0, 194, 80, 1);
        padding:2px 4px;
        display:block;
        border-radius:4px;
        box-sizing:border-box;
    }
    .content-color{
        color:rgba(0, 194, 80, 1); 
    }
    .content-color-act{
        color:rgba(245, 63, 63, 1);
    }
    .imgSize{
        width:30px;
        height:30px;
        display:block;
    }
    // 网络状态   在线
    .aline{
        color:rgba(0, 194, 80, 1);
    }
    // 网络状态  不在线
    .noAline{
        color:rgba(245, 63, 63, 1);
    }
    // 类型 单枪
    .oneDang{ 
        color:rgba(0, 194, 80, 1);
    }
    // 类型 双枪
    .twoDang{  
        color:rgba(71, 142, 255, 1);
    }
    // 日志 通讯  故障
    .fault{
        color:rgba(248, 182, 45, 1);
    }
    .fault-commStatus{
        color:rgba(245, 63, 63, 1);
    }
    // 等待中
    .wating{
        color:#909399;
        width:80px;
        border:2px solid #909399;
        text-align:center;
        padding:2px 4px;
        display:block;
        border-radius:4px;
        box-sizing:border-box;
    }
    .charging{
        color:#E6A23C;
        width:80px;
        border:2px solid #E6A23C;
        text-align:center;
        padding:2px 4px;
        display:block;
        border-radius:4px;
        box-sizing:border-box;
    }
    .queuing{
        color:#F53F3F;
        width:80px;
        border:2px solid #F53F3F;
        text-align:center;
        padding:2px 4px;
        display:block;
        border-radius:4px;
        box-sizing:border-box;
    }
    .paying{
        color:#478EFF;
        width:80px;
        border:2px solid #478EFF;
        text-align:center;
        padding:2px 4px;
        display:block;
        border-radius:4px;
        box-sizing:border-box;
    }
    .end{
        color:#00C250;
        width:80px;
        border:2px solid #00C250;
        text-align:center;
        padding:2px 4px;
        display:block;
        border-radius:4px;
        box-sizing:border-box;
    }

</style>

<style>
    .el-table th.el-table__cell{
        background:rgba(244, 243, 243, 1) !important;
    }
</style>